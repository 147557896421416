import extend from 'mdui.jq/es/functions/extend';
import commonActions from '~/utils/actionsAbstract';
import { emit } from '~/utils/pubsub';
const as = {
  onCreate: () => (state, actions) => {
    emit('route_update');
    actions.setTitle('');
  },
  onDestroy: () => {}
};
export default extend(as, commonActions);